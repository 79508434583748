// auth
export const SWITCH_SANDBOX = 'SWITCH_SANDBOX';
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const CLEAR_LOGIN_STATE = 'CLEAR_LOGIN_STATE';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const GET_PROFILE_START = 'GET_PROFILE_START';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILED = 'GET_PROFILE_FAILED';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const EDIT_PROFILE_START = 'EDIT_PROFILE_START';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAILED = 'EDIT_PROFILE_FAILED';
export const USER_LOGOUT = 'USER_LOGOUT';
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const SET_REGISTER_VALIDATE = 'SET_REGISTER_VALIDATE';
export const SET_DATA_REGISTER = 'SET_DATA_REGISTER';
export const SET_FORGOT_EMAIL = 'SET_FORGOT_EMAIL';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASWORD_FAILED';
export const IDLE_TIMEOUT_LOGOUT = 'IDLE_TIMEOUT_LOGOUT';
export const IDLE_TIMEOUT_WARNING = 'IDLE_TIMEOUT_WARNING';

// onboarding
export const TIPE_USAHA = 'TIPE_USAHA';
export const SET_STEP = 'SET_STEP';
export const SET_METODE_PEMBAYARAN = 'SET_METODE_PEMBAYARAN';
export const GET_METODE_PEMBAYARAN = 'GET_METODE_PEMBAYARAN';
export const GET_METODE_PEMBAYARAN_WITH_SELECTED = 'GET_METODE_PEMBAYARAN_WITH_SELECTED';
export const SET_PAYMENT_METHOD_SELECTED = 'SET_PAYMENT_METHOD_SELECTED';
export const SET_PAYMENT_CHANNEL_SELECTED = 'SET_PAYMENT_CHANNEL_SELECTED';
export const SET_INFORMASI_BISNIS = 'SET_INFORMASI_BISNIS';
export const SET_DETAIL_BANK = 'SET_DETAIL_BANK';
export const GET_BIZ_TYPE = 'GET_BIZ_TYPE';
export const GET_BIZ_CATEGORY = 'GET_BIZ_CATEGORY';
export const SET_INSURANCE = 'SET_INSURANCE';

// KEY
export const GET_KEY = 'GET_KEY';
export const UPDATE_MERCHANT_ACCOUNT = 'UPDATE_MERCHANT_ACCOUNT';

// USER MANAGEMENT
export const GET_USERS_LIST = 'GET_USERS_LIST';
export const ADD_USER = 'ADD_USER';
export const EDIT_USER = 'EDIT_USER';

// IP WHITELIST
export const GET_IP_WHITELIST = 'GET_IP_WHITELIST';
export const ADD_IP_WHITELIST = 'ADD_IP_WHITELIST';

// Merchant Preview
export const GET_MERCHANT_PREVIEW = 'GET_MERCHANT_PREVIEW';

// Qoin View
export const GET_THEME_LOGO = 'GET_THEME_LOGO';
export const GET_PAYMENT_CHANNEL = 'GET_PAYMENT_CHANNEL';
export const GET_PAYMENT_CHANNEL_SELECTED = 'GET_PAYMENT_CHANNEL_SELECTED';
export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD';
export const GET_PAYMENT_METHOD_SELECTED = 'GET_PAYMENT_METHOD_SELECTED';
export const GET_SELECTED_PAYMENT = 'GET_SELECTED_PAYMENT';
export const HANDLE_CHANGE_PAYMENT_CHANNEL = 'HANDLE_CHANGE_PAYMENT_CHANNEL';
export const HANDLE_CHANGE_PAYMENT_METHOD = 'HANDLE_CHANGE_PAYMENT_METHOD';

export const GET_MERCHANT = 'GET_MERCHANT';
export const DATA_ONBOARDING_EMPTY = 'DATA_ONBOARDING_EMPTY';
export const SET_IS_PRODUCTION = 'SET_IS_PRODUCTION';
export const SET_SUSPENDED_STATUS = 'SET_SUSPENDED_STATUS';
export const SET_INTERNAL_CODE = 'SET_INTERNAL_CODE';

export const SET_SELECTED_PAYMENT_TYPE = 'SET_SELECTED_PAYMENT_TYPE';

// RECIPIENTS
export const ADD_RECIPIENT = 'ADD_RECIPIENT';

//HEADER TITLE
export const SET_HEADER_TITLE = 'SET_HEADER_TITLE';

//TAB MENU
export const SET_PAYOUT_MENU = 'SET_PAYOUT_MENU';
export const SET_BENEFICIARIES_MENU = 'SET_BENEFICIARY_MENU';
export const SET_BALANCE_MENU = 'SET_BALANCE_MENU';
export const SET_REPORT_MENU = 'SET_REPORT_MENU';
export const SET_ACCOUNT_MENU = 'SET_ACCOUNT_MENU';
export const SET_SETTINGS_MENU = 'SET_SETTINGS_MENU';

//BENEFICIARIES
export const GET_BENEFICIARIES_START = 'GET_BENEFICIARIES_START';
export const GET_BENEFICIARIES_SUCCESS = 'GET_BENEFICIARIES';
export const GET_BENEFICIARIES_FAILED = 'GET_BENEFICIARIES_FAILED';
export const DELETE_BENEFICIARIES_START = 'DELETE_BENEFICIARIES_START';
export const DELETE_BENEFICIARIES_SUCCESS = 'DELETE_BENEFICIARIES_SUCCESS';
export const EDIT_BENEFICIARY_START = 'EDIT_BENEFICIARY_START';
export const EDIT_BENEFICIARY_SUCCESS = 'EDIT_BENEFICIARY_SUCCESS';
export const EDIT_BENEFICIARY_FAILED = 'EDIT_BENEFICIARY_FAILED';
export const EDIT_BENEFICIARY_RESET = 'EDIT_BENEFICIARY_RESET';
export const GET_ID_BENEFICIARY = 'GET_ID_BENEFICIARY';
export const DELETE_BENEFICIARIES_FAILED = 'DELETE_BENEFICIARIES_FAILED';
export const GET_TEMP_BENEFICIARIES = 'GET_TEMP_BENEFICIARIES';
export const GET_ALL_BENEFICIARIES_START = 'GET_ALL_BENEFICIARIES_START';
export const GET_ALL_BENEFICIARIES_SUCCESS = 'GET_ALL_BENEFICIARIES_SUCCESS';
export const GET_ALL_BENEFICIARIES_FAILED = 'GET_ALL_BENEFICIARIES_FAILED';
export const GET_ALL_BENEFICIARIES_CLEAR = 'GET_ALL_BENEFICIARIES_CLEAR';
export const ADD_BENEFICIARY_T0_GROUP_START = 'ADD_BENEFICIARY_TO_GROUP_START';
export const ADD_BENEFICIARY_TO_GROUP_SUCCESS = 'ADD_BENEFICIARY_TO_GROUP_SUCCESS';
export const ADD_BENEFICIARY_TO_GROUP_FAILED = 'ADD_BENEFICIARY_TO_GROUP_FAILED';
export const GET_GROUP_LIST_START = 'GET_GROUP_LIST_START';
export const GET_GROUP_LIST_SUCCESS = 'GET_GROUP_LIST_SUCCESS';
export const GET_GROUP_LIST_FAILED = 'GET_GROUP_LIST_FAILED';
export const DELETE_GROUP_START = 'DELETE_GROUP_START';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED';
export const EDIT_GROUP_START = 'EDIT_GROUP_START';
export const EDIT_GROUP_SUCCESS = 'EDIT_GROUP_SUCCESS';
export const EDIT_GROUP_FAILED = 'EDIT_GROUP_FAILED';
export const ADD_NEW_GROUP_START = 'ADD_NEW_GROUP_START';
export const ADD_NEW_GROUP_SUCCESS = 'ADD_NEW_GROUP_SUCCESS';
export const ADD_NEW_GROUP_FAILED = 'ADD_NEW_GROUP_FAILED';
export const GET_BENEFICIARIES_BY_GID_START = 'GET_BENEFICIARIES_BY_GID_START';
export const GET_BENEFICIARIES_BY_GID_SUCCESS = 'GET_BENEFICIARIES_BY_GID_SUCCESS';
export const GET_BENEFICIARIES_BY_GID_FAILED = 'GET_BENEFICIARIES_BY_GID_FAILED';
export const INQUIRY_START = 'INQUIRY_START';
export const INQUIRY_KEY = 'INQUIRY_KEY';
export const INQUIRY_SUCCESS = 'INQUIRY_SUCCESS';
export const INQUIRY_FAILED = 'INQUIRY_FAILED';
export const INQUIRY_RESET = 'INQUIRY_RESET';
export const POST_BENEFICIARY_START = 'POST_BENEFICIARY_START';
export const POST_BENEFICIARY_SUCCESS = 'POST_BENEFICIARY_SUCCESS';
export const POST_BENEFICIARY_FAILED = 'POST_BENEFICIARY_FAILED';
export const POST_BENEFICIARY_CLEAR_STATE = 'POST_BENEFICIARY_CLEAR_STATE';
export const DOWNLOAD_EXCEL_FORMAT_START = 'DOWNLOAD_EXCEL_FORMAT_START';
export const DOWNLOAD_EXCEL_FORMAT_SUCCESS = 'DOWNLOAD_EXCEL_FORMAT_SUCCESS';
export const DOWNLOAD_EXCEL_FORMAT_FAILED = 'DOWNLOAD_EXCEL_FORMAT_FAILED';
export const UPLOAD_EXCEL_START = 'UPLOAD_EXCEL_START';
export const UPLOAD_EXCEL_SUCCESS = 'UPLOAD_EXCEL_SUCCESS';
export const UPLOAD_EXCEL_FAILED = 'UPLOAD_EXCEL_FAILED';
export const UPLOAD_INQUIRY_SUCCESS = 'UPLOAD_INQUIRY_SUCCESS';
export const UPLOAD_INQUIRY_FAILED = 'UPLOAD_INQUIRY_FAILED';
export const UPLOAD_INQUIRY_RESET = 'UPLOAD_INQUIRY_RESET';
export const DOWNLOAD_INVALID_NUMBER_START = 'DOWNLOAD_INVALID_NUMBER_START';
export const DOWNLOAD_INVALID_NUMBER_SUCCESS = 'DOWNLOAD_INVALID_NUMBER_SUCCESS';
export const DOWNLOAD_INVALID_NUMBER_FAILED = 'DOWNLOAD_INVALID_NUMBER_FAILED';
export const GET_MASTER_BANK_START = 'GET_MASTER_BANK_START';
export const GET_MASTER_BANK_SUCCESS = 'GET_MASTER_BANK_SUCCESS';
export const GET_MASTER_BANK_FAILED = 'GET_MASTER_BANK_FAILED';

//BENEFICIARIES
export const GET_GROUP_LIST = 'GET_GROUP_LIST';

//SCHEDULER
export const GET_SCHEDULER_LIST = 'GET_SCHEDULER_LIST';
export const GET_RETENTION_LIST = 'GET_RETENTION_LIST';

//TRANSACTION
export const POST_TRANSACTION_START = 'POST_TRANSACTION_START';
export const POST_TRANSACTION_SUCCESS = 'POST_TRANSACTION_SUCCESS';
export const POST_TRANSACTION_FAILED = 'POST_TRANSACTION_FAILED';
export const GENERATE_SCHEDULER_DATE = 'GENERATE_SCHEDULER_DATE';
export const GENERATE_RETENTION_BODY = 'GENERATE_RETENTION_BODY';
export const SET_TRANSACTION_TYPE = 'SET_TRANSACTION_TYPE';
export const CLEAR_TRANSACTION_STATE = 'CLEAR_TRANSACTION_STATE';
export const GET_INSTANT_TRANSACTION = 'GET_INSTANT_TRANSACTION';
export const GET_SCHEDULER_TRANSACTION = 'GET_SCHEDULER_TRANSACTION';
export const GET_RETENTION_TRANSACTION = 'GET_RETENTION_TRANSACTION';
export const GET_RETENTION_DETAIL = 'GET_RETENTION_DETAIL';
export const GET_SCHEDULER_LIST_START = 'GET_SCHEDULER_LIST_START';
export const GET_SCHEDULER_LIST_SUCCESS = 'GET_SCHEDULER_LIST_SUCCESS';
export const GET_SCHEDULER_LIST_FAILED = 'GET_SCHEDULER_LIST_FAILED';
export const GET_RETENTION_LIST_START = 'GET_RETENTION_LIST_START';
export const GET_RETENTION_LIST_SUCCESS = 'GET_RETENTION_LIST_SUCCESS';
export const GET_RETENTION_LIST_FAILED = 'GET_RETENTION_LIST_FAILED';
export const GET_INSTANT_LIST_START = 'GET_INSTANT_LIST_START';
export const GET_INSTANT_LIST_SUCCESS = 'GET_INSTANT_LIST_SUCCESS';
export const GET_INSTANT_LIST_FAILED = 'GET_INSTANT_LIST_FAILED';
export const GET_ALL_TRANSACTION_LIST_START = 'GET_ALL_TRANSACTION_LIST_START';
export const GET_ALL_TRANSACTION_LIST_FAILED = 'GET_ALL_TRANSACTION_LIST_FAILED';
export const GET_ALL_TRANSACTION_LIST_SUCCESS = 'GET_ALL_TRANSACTION_LIST_SUCCESS';
export const GET_ALL_TRANSACTION_LIST_CLEAR = 'GET_ALL_TRANSACTION_LIST_CLEAR';
export const GET_DETAIL_TRANSACTION_START = 'GET_DETAIL_TRANSACTION_START';
export const GET_DETAIL_TRANSACTION_SUCCESS = 'GET_DETAIL_TRANSACTION_SUCCESS';
export const GET_DETAIL_TRANSACTION_FAILED = 'GET_DETAIL_TRANSACTION_FAILED';
export const APPROVE_TRANSACTION_START = 'APPROVE_TRANSACTION_START';
export const APPROVE_TRANSACTION_SUCCESS = 'APPROVE_TRANSACTION_SUCCESS';
export const APPROVE_TRANSACTION_FAIELD = 'APPROVE_TRANSACTION_FAILED';
export const APPROVE_TRANSACTION_RESET = 'APPROVE_TRANSACTION_RESET';
export const DELETE_TRANSACTION_START = 'DELETE_TRANSACTION_START';
export const DELETE_TRANSACTION_SUCCESS = 'DELETE_TRANSACTION_SUCCESS';
export const DELETE_TRANSACTION_FAILED = 'DELETE_TRANSACTION_FAILED';
export const GET_LOG_TRANSACTION_START = 'GET_LOG_TRANSACTION_START';
export const GET_LOG_TRANSACTION_SUCCESS = 'GET_LOG_TRANSACTION_SUCCESS';
export const GET_LOG_TRANSACTION_FAILED = 'GET_LOG_TRANSACTION_FAILED';

//PAYOUT-BENEFICIARY
export const GET_TEMP_BENEFICIARY_START = 'GET_TEMP_BENEFICIARY_START';
export const GET_TEMP_BENEFICIARY_SUCCESS = 'GET_TEMP_BENEFICIARY_SUCCESS';
export const GET_TEMP_BENEFICIARY_FAILED = 'GET_TEMP_BENEFICIARY_FAILED';
export const GET_TRANSFER_METHOD = 'GET_TRANSFER_METHOD';
export const GET_TRANSFER_METHOD_CLEAR = 'GET_TRANSFER_METHOD_CLEAR';
export const SAVE_TRANSFER_BENEFICIARY = 'SAVE_TRANSFER_BENEFICIARY';
export const SAVE_TRANSFER_BENEFICIARY_CLEAR = 'SAVE_TRANSFER_BENEFICIARY_CLEAR';
export const SAVED_BENEFICIARIES_TYPE = 'SAVED_BENEFICIARIES_TYPE';
export const GET_TEMP_GROUP = 'GET_TEMP_GROUP';
export const GET_TEMP_GROUP_CLEAR = 'GET_TEMP_GROUP_CLEAR';
export const GET_BENEF_BY_GROUP_START = 'GET_BENEF_BY_GROUP_START';
export const GET_BENEF_BY_GROUP_SUCCESS = 'GET_BENEF_BY_GROUP_SUCCESS';
export const GET_BENEF_BY_GROUP_FAILED = 'GET_BENEF_BY_GROUP_FAILED';

//REPORT-LIST
export const GET_REPORT_INSTANT_START = 'GET_REPORT_INSTANT_START';
export const GET_REPORT_INSTANT_SUCCESS = 'GET_REPORT_INSTANT_SUCCESS';
export const GET_REPORT_INSTANT_FAILED = 'GET_REPORT_INSTANT_FAILED';
export const GET_DETAIL_REPORT_INSTANT_START = 'GET_DETAIL_REPORT_INSTANT_START';
export const GET_DETAIL_REPORT_INSTANT_SUCCESS = 'GET_DETAIL_REPORT_INSTANT_SUCCESS';
export const GET_DETAIL_REPORT_INSTANT_FAILED = 'GET_DETAIL_REPORT_INSTANT_FAILED';
export const GET_REPORT_SCHEDULER_START = 'GET_REPORT_SCHEDULER_START';
export const GET_REPORT_SCHEDULER_SUCCESS = 'GET_REPORT_SCHEDULER_SUCCESS';
export const GET_REPORT_SCHEDULER_FAILED = 'GET_REPORT_SCHEDULER_FAILED';
export const GET_DETAIL_REPORT_SCHEDULER_START = 'GET_DETAIL_REPORT_SCHEDULER_START';
export const GET_DETAIL_REPORT_SCHEDULER_SUCCESS = 'GET_DETAIL_REPORT_SCHEDULER_SUCCESS';
export const GET_DETAIL_REPORT_SCHEDULER_FAILED = 'GET_DETAIL_REPORT_SCHEDULER_FAILED';
export const GET_REPORT_RETENTION_START = 'GET_REPORT_RETENTION_START';
export const GET_REPORT_RETENTION_SUCCESS = 'GET_REPORT_RETENTION_SUCCESS';
export const GET_REPORT_RETENTION_FAILED = 'GET_REPORT_RETENTION_FAILED';
export const GET_DETAIL_REPORT_RETENTION_START = 'GET_DETAIL_REPORT_RETENTION_START';
export const GET_DETAIL_REPORT_RETENTION_SUCCESS = 'GET_DETAIL_REPORT_RETENTION_SUCCESS';
export const GET_DETAIL_REPORT_RETENTION_FAILED = 'GET_DETAIL_REPORT_RETENTION_FAILED';
export const GET_ALL_REPORT_START = 'GET_ALL_REPORT_START';
export const GET_ALL_REPORT_SUCCESS = 'GET_ALL_REPORT_SUCCESS';
export const GET_ALL_REPORT_FAILED = 'GET_ALL_REPORT_FAILED';
export const GET_ALL_REPORT_CLEAR = 'GET_ALL_REPORT_CLEAR';

//Account
export const GET_ACCOUNT_LIST_START = 'GET_ACCOUNT_LIST_START';
export const GET_ACCOUNT_LIST_SUCCESS = 'GET_ACCOUNT_LIST_SUCCESS';
export const GET_ACCOUNT_LIST_FAILED = 'GET_ACCOUNT_LIST_FAILED';
export const GET_ACTIVITY_LOG_START = 'GET_ACTIVITY_LOG_START';
export const GET_ACTIVITY_LOG_SUCCESS = 'GET_ACTIVITY_LOG_SUCCESS';
export const GET_ACTIVITY_LOG_FAILED = 'GET_ACTIVITY_LOG_FAILED';
export const DELETE_USER_START = 'DELETE_USER_START';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED';
export const EDIT_USER_START = 'EDIT_USER_START';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILED = 'EDIT_USER_FAILED';
export const GET_DETAIL_USER_START = 'GET_DETAIL_USER_START';
export const GET_DETAIL_USER_SUCCESS = 'GET_DETAIL_USER_SUCCESS';
export const GET_DETAIL_USER_FAILED = 'GET_DETAIL_USER_FAILED';
export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const RESET_STATE = 'RESET_STATE_USER';
export const GET_USER_LIST_START = 'GET_USER_LIST_START';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_FAILED = 'GET_USER_LIST_FAILED';
export const GET_ROLE_LIST_START = 'GET_ROLE_LIST_START';
export const GET_ROLE_LIST_SUCCESS = 'GET_ROLE_LIST_SUCCESS';
export const GET_ROLE_LIST_FAILED = 'GET_ROLE_LIST_FAILED';

//balance
export const GET_MERCHANT_BALANCE_START = 'GET_MERCHANT_BALANCE_START';
export const GET_MERCHANT_BALANCE_SUCCESS = 'GET_MERCHANT_BALANCE_SUCCESS';
export const GET_MERCHANT_BALANCE_FAILED = 'GET_MERCHANT_BALANCE_FAILED';
export const GET_BALANCE_IN_OUT_START = 'GET_BALANCE_IN_OUT_START';
export const GET_BALANCE_IN_OUT_SUCCESS = 'GET_BALANCE_IN_OUT_SUCCESS';
export const GET_BALANCE_IN_OUT_FAILED = 'GET_BALANCE_IN_OUT_FAILED';
export const GET_MERCHANT_VA_START = 'GET_MERCHANT_VA_START';
export const GET_MERCHANT_VA_SUCCESS = 'GET_MERCHANT_VA_SUCCESS';
export const GET_MERCHANT_VA_FAILED = 'GET_MERCHANT_VA_FAILED';
export const GET_BALANCE_LIST_START = 'GET_BALANCE_LIST_START';
export const GET_BALANCE_LIST_SUCCESS = 'GET_BALANCE_LIST_SUCCESS';
export const GET_BALANCE_LIST_FAILED = 'GET_BALANCE_LIST_FAILED';
export const GET_ALL_BALANCE_LIST_START = 'GET_ALL_BALANCE_LIST_START';
export const GET_ALL_BALANCE_LIST_SUCCESS = 'GET_ALL_BALANCE_LIST_SUCCESS';
export const GET_ALL_BALANCE_LIST_FAILED = 'GET_ALL_BALANCE_LIST_FAILED';
export const GET_ALL_BALANCE_LIST_CLEAR = 'GET_ALL_BALANCE_LIST_CLEAR';

//settings merchant preview
export const GET_SETTING_MERCHANT_PREVIEW_START = 'GET_SETTING_MERCHANT_PREVIEW_START';
export const GET_SETTING_MERCHANT_PREVIEW_SUCCESS = 'GET_SETTING_MERCHANT_PREVIEW_SUCCESS';
export const GET_SETTING_MERCHANT_PREVIEW_FAILED = 'GET_SETTING_MERCHANT_PREVIEW_FAILED';
export const EDIT_SETTING_MERCHANT_PREVIEW_START = 'EDIT_SETTING_MERCHANT_PREVIEW_START';
export const EDIT_SETTING_MERCHANT_PREVIEW_SUCCESS = 'EDIT_SETTING_MERCHANT_PREVIEW_SUCCESS';
export const EDIT_SETTING_MERCHANT_PREVIEW_FAILED = 'EDIT_SETTING_MERCHANT_PREVIEW_FAILED';
export const DEACTIVE_MERCHANT_START = 'DEACTIVE_MERCHANT_START';
export const DEACTIVE_MERCHANT_SUCCESS = 'DEACTIVE_MERCHANT_SUCCESS';
export const DEACTIVE_MERCHANT_FAILED = 'DEACTIVE_MERCHANT_FAILED';
//create order
export const CREATE_ORDER_INSTANT_START = 'CREATE_ORDER_INSTANT_START';
export const CREATE_ORDER_INSTANT_SUCCESS = 'CREATE_ORDER_INSTANT_SUCCESS';
export const CREATE_ORDER_INSTANT_FAILED = 'CREATE_ORDER_INSTANT_FAILED';
export const CHECK_TRANSACTION_START = 'CHECK_TRANSACTION_START';
export const CHECK_TRANSACTION_SUCCESS = 'CHECK_TRANSACTION_SUCCESS';
export const CHECK_TRANSACTION_FAILED = 'CHECK_TRANSACTION_FAILED';
export const PAYMENT_CHANNEL_LIST_START = 'PAYMENT_CHANNEL_LIST_START';
export const PAYMENT_CHANNEL_LIST_SUCCESS = 'PAYMENT_CHANNEL_LIST_SUCCESS';
export const PAYMENT_CHANNEL_LIST_FAILED = 'PAYMENT_CHANNEL_LIST_FAILED';
export const POST_CREATE_ORDER_START = 'POST_CREATE_ORDER_START';
export const POST_CREATE_ORDER_SUCCESS = 'POST_CREATE_ORDER_SUCCESS';
export const POST_CREATE_ORDER_FAILED = 'POST_CREATE_ORDER_FAILED';
export const CLEAR_CREATE_ORDER_STATE = 'CLEAR_CREATE_ORDER_STATE';
export const SET_ID_CREATE_ORDER = 'SET_ID_CREATE_ORDER';
export const SET_PAYMENT_STEP = 'SET_PAYMENT_STEP';

//messaging
export const GET_LIST_MESSAGE_START = 'GET_LIST_MESSAGE_START';
export const GET_LIST_MESSAGE_SUCCESS = 'GET_LIST_MESSAGE_SUCCESS';
export const GET_LIST_MESSAGE_FAILED = 'GET_LIST_MESSAGE_FAILED';

//dashboard
export const GET_DASHBOARD_START = 'GET_DASHBOARD_START';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILED = 'GET_DASHBOARD_FAILED';

//System Settings
export const GET_SETTING_SECURITY_START = 'GET_SETTING_SECURITY_START';
export const GET_SETTING_SECURITY_SUCCESS = 'GET_SETTING_SECURITY_SUCCESS';
export const GET_SETTING_SECURITY_FAILED = 'GET_SETTING_SECURITY_FAILED';
export const POST_GENERATE_SECRET_START = 'POS_GENERATE_SECRET_START';
export const POST_GENERATE_SECRET_SUCCESS = 'POS_GENERATE_SECRET_SUCCESS';
export const POST_GENERATE_SECRET_FAILED = 'POS_GENERATE_SECRET_FAILED';
export const POST_GENERATE_KEY_START = 'POST_GENERATE_KEY_START';
export const POST_GENERATE_KEY_SUCCESS = 'POST_GENERATE_KEY_SUCCESS';
export const POST_GENERATE_KEY_FAILED = 'POST_GENERATE_KEY_FAILED';
export const PUT_SETTING_SECURITY_START = 'PUT_SETTING_SECURITY_START';
export const PUT_SETTING_SECURITY_SUCCESS = 'PUT_SETTING_SECURITY_SUCCESS';
export const PUT_SETTING_SECURITY_FAILED = 'PUT_SETTING_SECURITY_FAILED';
export const GET_IP_WHITELIST_START = 'GET_IP_WHITELIST_START';
export const GET_IP_WHITELIST_SUCCESS = 'GET_IP_WHITELIST_SUCCESS';
export const GET_IP_WHITELIST_FAILED = 'GET_IP_WHITELIST_FAILED';
export const PUT_IP_WHITELIST_START = 'PUT_IP_WHITELIST_START';
export const PUT_IP_WHITELIST_SUCCESS = 'PUT_IP_WHITELIST_SUCCESS';
export const PUT_IP_WHITELIST_FAILED = 'PUT_IP_WHITELIST_FAILED';
export const GET_SETTING_MESSAGE_START = 'GET_SETTING_MESSAGE_START';
export const GET_SETTING_MESSAGE_SUCCESS = 'GET_SETTING_MESSAGE_SUCCESS';
export const GET_SETTING_MESSAGE_FAILED = 'GET_SETTING_MESSAGE_FAILED';
export const PUT_SETTING_MESSAGE_START = 'PUT_SETTING_MESSAGE_START';
export const PUT_SETTING_MESSAGE_SUCCESS = 'PUT_SETTING_MESSAGE_SUCCESS';
export const PUT_SETTING_MESSAGE_FAILED = 'PUT_SETTING_MESSAGE_FAILED';
