import React from 'react';
import CustomTableRow from '../Table_Row/Component';
import CustomTableCell from '../Table_Cell/Component';
import i18next from 'i18next';
const TableNoData = () => {
  return (
    <CustomTableRow>
      <CustomTableCell colSpan='9' style={{ textAlign: 'center' }}>
        {i18next.t('table.empty')}
      </CustomTableCell>
    </CustomTableRow>

  );
};

export default TableNoData;
