import update from 'immutability-helper';
import {
  CHECK_TRANSACTION_FAILED,
  CHECK_TRANSACTION_START,
  CHECK_TRANSACTION_SUCCESS,
  CLEAR_CREATE_ORDER_STATE,
  CREATE_ORDER_INSTANT_FAILED,
  CREATE_ORDER_INSTANT_START,
  CREATE_ORDER_INSTANT_SUCCESS,
  PAYMENT_CHANNEL_LIST_FAILED,
  PAYMENT_CHANNEL_LIST_START,
  PAYMENT_CHANNEL_LIST_SUCCESS,
  POST_CREATE_ORDER_FAILED,
  POST_CREATE_ORDER_START,
  POST_CREATE_ORDER_SUCCESS,
  SET_ID_CREATE_ORDER,
  SET_PAYMENT_STEP,
} from '../actions/actionType';

const initialState = {
  isLoading: false,
  message: '',
  id: {},
  data: {},
  postCreateOrderData: {},
  paymentChannel: {},
  paymentStep: '',
};

function createOrderReducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ORDER_INSTANT_START:
      return update(state, {
        isLoading: { $set: true },
        message: { $set: '' },
        id: { $set: {} },
      });
    case CREATE_ORDER_INSTANT_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        id: { $set: action.payload },
      });
    case CREATE_ORDER_INSTANT_FAILED:
      return update(state, {
        isLoading: { $set: false },
        message: { $set: action.payload },
      });
    case CHECK_TRANSACTION_START:
      return update(state, {
        isLoading: { $set: true },
        message: { $set: '' },
      });
    case CHECK_TRANSACTION_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        data: { $set: action.payload },
      });
    case CHECK_TRANSACTION_FAILED:
      return update(state, {
        isLoading: { $set: false },
        message: { $set: action.payload },
      });
    case PAYMENT_CHANNEL_LIST_START:
      return update(state, {
        isLoading: { $set: true },
        message: { $set: '' },
        paymentChannel: { $set: {} },
      });
    case PAYMENT_CHANNEL_LIST_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        paymentChannel: { $set: action.payload },
      });
    case PAYMENT_CHANNEL_LIST_FAILED:
      return update(state, {
        isLoading: { $set: false },
        message: { $set: action.payload },
      });
    case POST_CREATE_ORDER_START:
      return update(state, {
        isLoading: { $set: true },
        message: { $set: '' },
      });
    case POST_CREATE_ORDER_SUCCESS:
      return update(state, {
        isLoading: { $set: false },
        postCreateOrderData: { $set: action.payload },
      });
    case POST_CREATE_ORDER_FAILED:
      return update(state, {
        isLoading: { $set: false },
        message: { $set: action.payload },
      });
    case CLEAR_CREATE_ORDER_STATE:
      return update(state, {
        isLoading: { $set: false },
        message: { $set: '' },
        id: { $set: {} },
        data: { $set: {} },
        postCreateOrderData: { $set: {} },
        paymentChannel: { $set: {} },
      });
    case SET_ID_CREATE_ORDER:
      return update(state, {
        id: { $set: action.payload },
      });
    case SET_PAYMENT_STEP:
      return update(state, {
        paymentStep: { $set: action.payload },
      });
    default:
      return state;
  }
}

export default createOrderReducer;
