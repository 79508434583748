import i18next from "i18next";

export const GetStatusTransaction = (v) => {

    let statusName = "-";
    let statusStyle = "";
    switch (v) {
        case 10:
            statusName = "Created"
            statusStyle = "transactionCreatedStatus"
            break;
        case 80:
            statusName = "Created VA"
            statusStyle = "transactionCreatedStatus"
            break;
        case 90:
            statusName = "Needs Action"
            statusStyle = "transactionWaitingorApproved"
            break;
        case 100:
            statusName = "Approved"
            statusStyle = "valid-status"
            break;
        case 110:
            statusName = "Processed"
            statusStyle = "transactionProcessedStatus"
            break;
        case 111:
            statusName = "Cancelled"
            statusStyle = "transactionCancelledStatus"
            break;
        case 112:
            statusName = "Rejected"
            statusStyle = "transactionRejectedStatus"
            break;
        case 113:
            statusName = "Refund"
            statusStyle = "transactionRejectedStatus"
            break;
        case 150:
            statusName = "Paused"
            statusStyle = "transactionPausedStatus"
            break;
        case 200:
            statusName = "Done"
            statusStyle = "transactionDoneStatus"
            break;
        case 210:
            statusName = "Expired"
            statusStyle = "transactionExpiredStatus"
            break;
        case 211:
            statusName = "Deleted"
            statusStyle = "transactionDeletedStatus"
            break;
        default:
            statusName = "-"
            statusStyle = "TransactionDeletedStatus"
            break;
    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ cursor: "default", marginRight: "0.5rem", whiteSpace: "nowrap" }}>
                <span className={statusStyle}>{i18next.t(`status.${statusName}`)}</span>
            </div>
        </div>
    )
}