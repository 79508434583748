import update from 'immutability-helper';
import {
  DATA_ONBOARDING_EMPTY,
  GET_MERCHANT,
  SET_IS_PRODUCTION,
  SET_SUSPENDED_STATUS,
  SET_INTERNAL_CODE,
} from '../actions/actionType';

const initialState = {
  merchant: {},
  isProduction: false,
  isDataEmpty: false,
  isSuspended: false,
  suspendedDate: '',
  internalCode: '',
};

function merchantReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MERCHANT:
      return update(state, {
        merchant: { $set: action.payload },
        isDataEmpty: { $set: false },
      });
    case DATA_ONBOARDING_EMPTY:
      return update(state, {
        isDataEmpty: { $set: true },
      });
    case SET_IS_PRODUCTION:
      return update(state, {
        isProduction: { $set: action.payload },
      });
    case SET_SUSPENDED_STATUS:
      return update(state, {
        isSuspended: { $set: action.payload },
      });
    case SET_INTERNAL_CODE:
      return update(state, {
        internalCode: { $set: action.payload },
      });
    default:
      return state;
  }
}

export default merchantReducer;
