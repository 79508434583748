import i18next from "i18next";

export const GetStatusBenef = (status) => {
    let statusTag;
  
    switch (status) {
      case true:
        statusTag = <span className='valid-status'>{i18next.t('status.Valid')}</span>;
        break;
      case false:
        statusTag = <span className='invalid-status'>{i18next.t('status.Invalid')}</span>;
        break;
      default:
        statusTag = <span className='invalid-status'>{status}</span>;
        break;
    }
  
    return <div className='d-flex justify-content-center'>{statusTag}</div>;
  };