import i18next from "i18next";

export const LogTransaction = (log) => {
  let logName = '';

  switch (log) {
    case 10:
      logName = 'Transaction Payout Created';
      break;
    case 80:
      logName = 'Transaction Created VA';
      break;
    case 90:
      logName = 'Waiting for Approved';
      break;
    case 95:
      logName = 'VA Paid';
      break;
    case 100:
      logName = 'Transaction Payout Approved';
      break;
    case 110:
      logName = 'Transaction Payout Processed';
      break;
    case 111:
      logName = 'Transaction Payout Cancelled';
      break;
    case 112:
      logName = 'Transaction Payout Rejected';
      break;
    case 113:
      logName = 'Transaction Payout Refund';
      break;
    case 150:
      logName = 'Transaction Payout Paused';
      break;
    case 200:
      logName = 'Transaction Payout Done';
      break;
    case 210:
      logName = 'Transaction Payout Expired';
      break;
    case 211:
      logName = 'Transaction Payout Deleted';
      break;
    default:
      logName = '-';
      break;
  }

  return <div>{i18next.t(`log.${logName}`)}</div>;
};
