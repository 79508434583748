import React from 'react';
import { TableCell, TableHead, TableRow, ThemeProvider, Typography, createTheme } from '@mui/material';
import CustomTableCell from '../Table_Cell/Component';
import i18next from 'i18next';

const theme = createTheme({
  components: {
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#fef4e2',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 700,
          fontFamily: 'Manrope',
          padding: '0.5rem 0.5rem',
        },
      },
    },
  },
});

const CustomTableHead = ({ header, hideAction, hideNo, children, align }) => {
  return (
    <ThemeProvider theme={theme}>
      <TableHead>
        {header.length > 0 ? (
          <TableRow sx={{ height: 50 }}>
            {!hideNo ? (
              <TableCell align='center' style={{ minWidth: 2 }}>
                <Typography>NO</Typography>
              </TableCell>
            ) : (
              <></>
            )}
            {header &&
              header?.length > 0 &&
              header.map((data, index) => (
                <CustomTableCell align={align ? align : 'center'} sx={{ maxWidth: '200px' }} key={index}>
                  {i18next.t(`table.header.${data.label}`).toUpperCase()}
                </CustomTableCell>
              ))}
            {!hideAction ? (
              <CustomTableCell align='center'>{i18next.t('table.header.Action').toUpperCase()}</CustomTableCell>
            ) : (
              <></>
            )}
          </TableRow>
        ) : (
          <>{children}</>
        )}
      </TableHead>
    </ThemeProvider>
  );
};

export default CustomTableHead;
